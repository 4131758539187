<template>
    <section id="workingArea">
        <div id="tabSec" class="align-items-center">
            <div class="balance" >
                <div class="row items-center justify-between rounded-borders inner q-mb-md" 
                    style="margin-left:0;margin-right:0">
                    <div class="col-4 text-center">
                        <div>
                            <span class="label" style="font-size:18px">Total Staked</span><br>
                            <span class="s1">{{getTotalStaked}}</span>
                            <span class="q-ml-sm token_lg">StarCards</span>
                        </div>
                    </div>
                    <div class="col-4 text-center">
                        <div>
                            <span class="label" style="font-size:18px">Your Staked</span><br>
                            <span class="s1">{{getYourStaked}}</span>
                            <span class="q-ml-sm token_lg">StarCards</span>
                        </div>
                    </div>
                </div>
                <div class="row items-center justify-between rounded-borders inner q-mb-md text-center"
                    style="margin-left:0;margin-right:0">
                    <div class="col-12 text-center">
                        <span class="label" style="font-size:18px">Earn $STAR up to</span><br>
                        <span class="s1">{{getAPR}}% APR</span>
                    </div>
                    <!-- <div class="col-6 text-right" style="color:white">
                        <div>
                            <span class="label" style="font-size:18px">Daily Reward</span><br>
                            <span class="s1">{{getDailyReward}}</span>
                            <span class="q-ml-sm token_lg">StarCards</span>
                        </div>
                    </div> -->
                </div>
                <div class="tabs">
                    <div class="tab">
                        <button :class=" tab == 0 ? 'active': ''" @click="tab = 0">Stake</button>
                    </div>
                    <div class="tab">
                        <button :class=" tab == 1 ? 'active': ''" 
                            @click="tab = 1">UnStake
                        </button>
                    </div>
                </div>
                <div v-if="tab == 1" 
                    class="col-12 flex justify-between items-center q-pa-sm text-white text-bold">
                    <div class="q-my-md text-center">
                        <span class="label">Total Claimable</span><br>
                        <span class="label">{{getClaimableReward}}</span>
                        <span class="token"> STAR</span>
                    </div>
                    <div class="text-center">
                        <span class="label">Total Claimed</span><br>
                        <span class="label">{{getClaimedReward}}</span>
                        <span class="token"> STAR</span>
                    </div>
                </div>
                <div v-if="tab == 1"  class="text-h7 col-12 justify-center flex q-pb-md q-mt-md">
                    <div class="btn" :disabled="!isMetaMaskConnected" @click="claim">
                        Claim Rewards
                    </div>
                </div>
                <div v-if="!isMetaMaskConnected" class="walletBtn q-mt-md" @click="connectWallet">Connect Wallet</div>
                <div v-if="tab == 0 && isMetaMaskConnected" class="walletBtn q-mt-md" @click="searchUnStaked">Show Your StarCards</div>
                <div v-if="tab == 1 && isMetaMaskConnected" class="walletBtn q-mt-md" @click="searchStaked">Show Your Staked StarCards</div>
            </div>
        </div>
        <div class = "row my-7 mx-0 text-white q-mt-lg" 
            v-if="tab == 0 && this.$store.state.nftStaking.searchUnStakedResult" 
            style="width:100%; padding-left:5px;">
            <div class="col-lg-2 col-md-2 col-sm-12 flex justify-center q-mt-lg text-white " 
                v-for="(imageURI,idx) in this.$store.state.nftStaking.unStakedimageURIs"
                :key="idx">
                <div class="nft_card_sm">
                    <div class="nft_card_image_sm" v-lazy-container="{ selector: 'img' }">
                        <img class="mintedImage"
                            :data-src="imageURI"
                            :data-loading="loadimage"
                            contain
                        />
                    </div>
                    <div class="sub_panel text-justify text-center q-mt-sm">
                        <!-- <div class = "tokenNames">{{showShortName(idx, 0)}}</div>
                        <div class = "tokenRarity">Rarity Rank: {{tokenAttributes(idx, 0).value}}</div> -->
                        <div v-if="isApproved" class="walletBtn_sm1" @click="stake(idx)">
                            <!-- <label>
                                <input type="checkbox">
                                <span class="q-mx-sm">Stake</span>
                            </label> -->
                            Stake
                        </div>
                        <div v-if="!isApproved" class="walletBtn_sm1" @click="approve">
                            <!-- <label>
                                <input type="checkbox">
                                <span class="q-mx-sm">Stake</span>
                            </label> -->
                            Approve
                        </div>
                    </div>
                </div>              
           </div>
        </div>
        <div class = "row my-7 mx-0 text-white q-mt-lg" 
            v-if="tab == 1 && this.$store.state.nftStaking.searchStakedResult" 
            style="width:100%; padding-left:5px;">
            <div class="col-lg-2 col-md-2 col-sm-12 flex justify-center q-mt-lg text-white " 
                v-for="(imageURI,idx) in this.$store.state.nftStaking.stakedimageURIs"
                :key="idx">
                <div class="nft_card_sm">
                    <div class="nft_card_image_sm" v-lazy-container="{ selector: 'img' }">
                        <img class="mintedImage"
                            :data-src="imageURI"
                            :data-loading="loadimage"
                            contain
                        />
                    </div>
                    <div class="sub_panel text-justify text-center q-mt-sm">
                        <!-- <div class = "tokenNames">{{showShortName(idx, 1)}}</div>
                        <div class = "tokenRarity">Rarity Rank: {{tokenAttributes(idx, 1).value}}</div> -->
                        <div class="walletBtn_sm1" @click="unStake(idx)">
                            <!-- <label>
                                <input type="checkbox">
                                <span class="q-mx-sm">UnStake</span>
                            </label> -->
                            UnStake
                        </div>
                    </div>
                </div>              
           </div>
        </div>
        <!-- <div v-if="this.$store.state.starCardsnft.searchResult"  class="text-white text-center">
            <div v-if="tab == 0" class="walletBtn_sm stake" @click="search" 
               >Stake</div>
            <div v-if="tab == 1" class="walletBtn_sm stake" @click="search" 
               >UnStake</div>
        </div> -->
    </section>
</template>
<script>
    import { BigNumber } from 'bignumber.js'

    export default {
        components: {
        },
        data() {
            return {
                loadimage: require("@/assets/loading.gif"),
                pending: false,
                error: false,
                address: null,
                tokenId: null,
                transfered: false,
                tab:0
            }
        },
        mounted() {
            this.$store.commit('read_nftStaking');
        },
        watch:{
        },
        computed: {   
            isMetaMaskConnected() {
                return this.$store.state.account != null;
            },
            getClaimableReward() {
                if(this.$store.state.nftStaking.claimableReward > 0)
                    return this.getDecimalData(BigNumber(this.$store.state.nftStaking.claimableReward)
                        .shiftedBy(-18));

                return '0.00';
            },
            getClaimedReward() {
                if(this.$store.state.nftStaking.claimedReward > 0)
                    return this.getDecimalData(BigNumber(this.$store.state.nftStaking.claimedReward)
                    .shiftedBy(-18));

                return '0.00';
            },
            isApproved() {
                return this.$store.state.approvedNFTStaking;
            },
            getAPR() {
                if (this.$store.state.nftStaking.poolInfo[0] == undefined)
                    return 0;

                let emissionRate = BigNumber(this.$store.state.nftStaking.poolInfo[5])
                            .shiftedBy(-18).toString();
                let floorPrice = this.$store.state.starCardsnft.floorPrice;
                let starPrice = this.$store.state.price.STAR;
                let astrPrice = this.$store.state.price.ASTR;

                let apr = 5 * 60 * 24 * 365 * Number(emissionRate) * starPrice * 100 / (floorPrice * astrPrice);

                return BigNumber(apr).toFormat(2);
            },
            getDailyReward() {
                let emissionRate = BigNumber(this.$store.state.nftStaking.poolInfo[5])
                            .shiftedBy(-18).toString();

                let value = BigNumber(5 * 60 * 24 * Number(emissionRate)).toFormat(0);
                return value;
            },
            getTotalStaked() {
                if (this.$store.state.nftStaking.poolInfo[6] > 0) {
                    let value = this.$store.state.nftStaking.poolInfo[6];
                    return value;
                }
                else
                    return 0;
            },
            getYourStaked() {
                let value = this.$store.state.nftStaking.userStakedNFT;
                return value;
            }
        },
        methods: {
            connectWallet() {
                this.$store.dispatch('connect');
            },
            async searchUnStaked() {
                this.pending = true;
                try {
                    let result;
                    result = await this.$store.dispatch('getUnStakedTokenIdsOfOwner', {
                        account:this.$store.state.account.address
                    })

                    if (!result) {
                        this.pending = false;
                        this.error = true;
                        return;
                    }

                    await this.$store.dispatch('constructUnStakedTokenInfo')

                    this.error = false;
                }
                catch (e) {
                    this.error = e;
                }
                this.pending = false;
            },
            async searchStaked() {
                this.pending = true;
                try {
                    let result;
                    result = await this.$store.dispatch('getStakedTokenIdsOfOwner', {
                        account:this.$store.state.account.address
                    })

                    if (!result) {
                        this.pending = false;
                        this.error = true;
                        return;
                    }

                    await this.$store.dispatch('constructStakedTokenInfo')

                    this.error = false;
                }
                catch (e) {
                    this.error = e;
                }
                this.pending = false;
            },
            showShortName(index, kind) {
                let name;
                if (kind == 0)
                    name = this.$store.state.nftStaking.unStakedtokenNames[index];
                else
                    name = this.$store.state.nftStaking.stakedtokenNames[index];

                if (name.length < 20)
                    return '"' + name + '"'
                else
                    return (
                        '"' + name.substring(0, 10) +
                        '...' +
                        name.substring(name.length - 8, name.length) + '"'
                    );
            },
            tokenAttributes(index, kind) {
                if (kind == 0)
                    return this.$store.state.nftStaking.unStakedtokenAttributes[index][0];
                else
                    return this.$store.state.nftStaking.stakedtokenAttributes[index][0];
            },
            claim() {
                this.$store.dispatch('claimRewardsForNFTStaking')
            },
            approve() {
                this.$store.dispatch('approveNFTStaking');
            },
            stake(index) {
                let tokenId = this.$store.state.nftStaking.unStakedtokenIds[index];
                this.$store.dispatch('stakeNFT', {tokenId}).then(()=>{
//                    this.searchUnStaked();
                });
            },
            unStake(index) {
                let tokenId = this.$store.state.nftStaking.stakedtokenIds[index];
                this.$store.dispatch('UnStakeNFT', {tokenId}).then((ret)=>{
//                    this.searchStaked();
                });
            },
            getDecimalData(value) {
                let limit_xxx = BigNumber(1000);
                let limit_xx = BigNumber(1);
                let limit_x = BigNumber(1).shiftedBy(-3);
                let limit_sm = BigNumber(1).shiftedBy(-6);
                let limit_md = BigNumber(1).shiftedBy(-9);
                let limit_lg = BigNumber(1).shiftedBy(-12);

                if (limit_lg.isGreaterThan(value)) {
                    return value.toString();
                }
                else if (limit_md.isGreaterThan(value)) {
                    return value.decimalPlaces(12, 1).toString();
                }
                else if (limit_sm.isGreaterThan(value)) {
                    return value.decimalPlaces(9, 1).toString();
                }
                else if (limit_x.isGreaterThan(value)) {
                    return value.decimalPlaces(6, 1).toString();
                }
                else if (limit_xx.isGreaterThan(value)) {
                    return value.decimalPlaces(4, 1).toString();
                }
                else if (limit_xxx.isGreaterThan(value)) {
                    return value.decimalPlaces(2, 1).toString();
                }
                else {
                    return value.decimalPlaces(0, 1).toString();
                }
            }
        }
    }
</script>
<style>
    .tabs {
        width: 100%;
        display: flex;
        border-radius: 10px;
        padding: 2px;
        background: var(--q-color-secondary)
    }
    .tab {
        flex: 1;
        /* display: inline; */
    }
    .tab > button {
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 7px;
        margin: 2px;
        width: 100%;
        color: #999;
        border: none;
        cursor: pointer;
    }
    .tab > .active {
        background-image: linear-gradient(to right, var(--q-color-primary), var(--q-color-info));
        color: #fff;
        font-weight: 600;
    }

    .stake {
        padding:15px 20px !important; 
        width:200px !important
    }

    label {
        margin-bottom:0 !important;
    }

    .s1 {
        color: rgba(171, 196, 255, 0.9);
        /* background: linear-gradient(-45deg, #7f0281, #4B50E6, #7f0281, #4B50E6);
        background-size: 100% 100%;
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent; */
        font-size:20px;
        font-weight:500;
    }
</style>
